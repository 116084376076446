@media all and (min-width: 480px) {

  .Login {
   /* padding: 60px 0;*/
  }

  .Login Button {
  	background: #1471b1;
  	color: white;
  	padding: 16px;
  }

  .Login form {
  	border: 1px solid #cccccc;
  	padding: 32px;
    margin: 0 auto;
    max-width: 320px;
  }

  .headpanel2 {

  	background: floralwhite;
  	padding: 16px;
  	margin-bottom: 64px;
  }
}
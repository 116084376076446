.p {
    &__grid {
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax('100%', 1fr));
        grid-gap: 0px;
    }
}

.textbold {
	font-weight: bold;
}

.hidden {
    display: none
}
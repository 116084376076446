.headerwrapper {
    
    text-align: center;
    vertical-align: top;    
    width: 100%;
    display: inline-block;
    background: floralwhite;
}

.headerlinks {
    text-align: right;
    width: 100%;
    display: inline-block;
}

.logo {
    
    text-align: left;
    width: 100%;
    display: inline-block;
}

.header {
    padding: 10px;
    text-align: right;

    a {
        display: inline-block;
        margin-right: 10px;
        padding: 5px 10px;
        svg {
            width: 18px;
            margin-right: 5px;
        }
    }
}

.headertext {
    
    font-weight: bold;
    font-size: 18px;
}

.active {
    
    font-weight: 600;
    font-size: 20px;
    text-transform: uppercase;
}

.loggedin {
    
    font-weight: 700;
    font-size: 16px;
    color: blue;
}
.p {
    &__grid {
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax('100%', 1fr));
        grid-gap: 0px;
    }
}

b {
	
	font-weight: 600;
    color: #1a1a1a;
}